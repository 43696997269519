export class TaskSegment implements TaskSegmentInterface {
  readonly id: number;
  readonly task_content_id: number;
  readonly source_language_tag_code: string;
  readonly destination_language_tag_code: string;
  readonly source_old_text: string;
  readonly destination_old_text: string;
  readonly status: TaskSegmentStatus;
  readonly position: number;
  readonly meta: TaskSegmentMeta;
  readonly reviewed_at: string;
  readonly created_at: string;
  readonly updated_at: string;

  source_text: string;
  destination_text: string;
  autofill: TaskSegmentAutofill;

  public isLoading = false;

  constructor(segment: TaskSegmentInterface, isLoading = false) {
    this.id = segment.id;
    this.task_content_id = segment.task_content_id;
    this.source_language_tag_code = segment.source_language_tag_code;
    this.destination_language_tag_code = segment.destination_language_tag_code;
    this.source_text = segment.source_text;
    this.destination_text = segment.destination_text;
    this.source_old_text = segment.source_old_text;
    this.destination_old_text = segment.destination_old_text;
    this.status = segment.status;
    this.position = segment.position;
    this.meta = segment.meta;
    this.reviewed_at = segment.reviewed_at;
    this.created_at = segment.created_at;
    this.updated_at = segment.updated_at;
    this.autofill = segment.autofill;
    this.isLoading = isLoading;
  }

  get hasDestinationText(): boolean {
    return this.destination_text.trim().length > 0;
  }

  get hasNbrInDestinationText(): boolean {
    return this.destination_text.match(/%NBR%|(?:%NBR)\d+%/i) !== null;
  }

  changeDestinationText(destinationIext: string): void {
    this.destination_text = destinationIext;
  }
}
