import { TaskSegment } from '@/modules/articles/entities/TaskSegment';
import { v4 } from 'uuid';

export class TaskSegments {
  readonly tag: TaskSegmentMetaTag = null;
  segments: TaskSegment[] = null;
  public isLoading = false;

  static createEmpty(
    segmentsCount = 3,
    type: TaskSegmentMetaTagType = '@@'
  ): TaskSegments {
    const tag = {
      name: 'tag',
      position: 0,
      segment_position: 0,
      type,
      uuid: v4(),
    };
    return new this({
      tag,
      segments: new Array(segmentsCount).fill(null).map(() =>
        this.createNewSegmentOutOfSegment(tag, {
          meta: {},
        } as TaskSegmentInterface)
      ),
    });
  }

  static createNewSegmentOutOfSegment(
    tag: TaskSegmentMetaTag,
    segment: TaskSegmentInterface
  ): TaskSegmentInterface {
    return {
      ...segment,
      id: null,
      task_content_id: null,
      source_text: '',
      destination_text: '',
      meta: {
        ...segment.meta,
        tag: {
          ...segment.meta.tag,
          name: tag.name,
          type: tag.type,
          uuid: tag.uuid,
        },
      },
    };
  }

  constructor({
    tag,
    segments,
    isLoading = false,
  }: {
    tag: TaskSegmentMetaTag;
    segments: TaskSegmentInterface[];
    isLoading?: boolean;
  }) {
    this.tag = tag;
    this.segments = segments
      .sort((a, b) => a.meta.tag.segment_position - b.meta.tag.segment_position)
      .map((segment) => new TaskSegment(segment));

    this.isLoading = isLoading;
  }

  get position(): number {
    return this.tag.position;
  }

  append(): void {
    const lastSegment = TaskSegments.createNewSegmentOutOfSegment(
      this.tag,
      this.segments[this.segments.length - 1]
    );

    this.segments.push(new TaskSegment(lastSegment));
  }

  prepend(): void {
    const firstSegment = TaskSegments.createNewSegmentOutOfSegment(
      this.tag,
      this.segments[0]
    );

    this.segments.unshift(new TaskSegment(firstSegment));
  }
}
