import { api } from '@/api';
import { AxiosResponse } from 'axios';
import { LocationQuery } from 'vue-router';

export class TaskContentService {
  static bulkUpdate(
    taskContentId: number,
    selectedContent: TaskContent<TaskContentSegmentsArray>
  ): Promise<AxiosResponse<TaskContent<TaskContentSegmentsModifiedArray>>> {
    return api.put(
      `/api/article-description/translation/task-content/${taskContentId}/bulk-update`,
      { selected_content: selectedContent }
    );
  }

  static fetchTaskContent(
    taskContentId: number
  ): Promise<AxiosResponse<TaskContentInterface>> {
    return api.get(
      `/api/article-description/translation/task-content/${taskContentId}`
    );
  }

  static updateTaskSegmentDestinationText(
    segmentId: number,
    destinationText: string
  ): Promise<AxiosResponse<TaskSegment>> {
    return api.patch(
      `/api/article-description/translation/task-segment/${segmentId}/update-destination-text`,
      { destination_text: destinationText }
    );
  }

  static swapTaskSegmentText(
    fromId: number,
    toId: number
  ): Promise<AxiosResponse<void>> {
    return api.patch(
      `/api/article-description/translation/task-segment/swap-destination-text`,
      {
        from: fromId,
        to: toId,
      }
    );
  }

  static finish(
    taskId: number,
    ignoreForbidden = false
  ): Promise<AxiosResponse<ProjectTask>> {
    return api.patch(
      `/api/article-description/translation/task/${taskId}/finish`,
      { ignore_forbidden: ignoreForbidden }
    );
  }

  static showSuggestionsAndGlossariesBySegment(segmentId: number): Promise<
    AxiosResponse<{
      suggestions: TranslationMemory[];
      glossaries: TranslationViewGlossaryResponse[];
    }>
  > {
    return api.get(
      `/api/article-description/translation/task-segment/${segmentId}/suggestions-and-terms`
    );
  }

  static next(
    currentTaskId: number,
    query: LocationQuery
  ): Promise<AxiosResponse<ProjectTask>> {
    return api.get(
      `/api/article-description/translation/task/${currentTaskId}/next`,
      {
        params: query,
      }
    );
  }
}
