import { defineAsyncComponent } from 'vue';
export const OutlineArrowLeftOnRectangle = defineAsyncComponent(
  () =>
    import(
      '@/components/icons/heroicons/outline/OutlineArrowLeftOnRectangle.vue'
    )
);

export const OutlineArrowNarrowRight = defineAsyncComponent(
  () =>
    import('@/components/icons/heroicons/outline/OutlineArrowNarrowRight.vue')
);

export const OutlineArrowPath = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineArrowPath.vue')
);

export const OutlineArrowTopRightOnSquare = defineAsyncComponent(
  () =>
    import(
      '@/components/icons/heroicons/outline/OutlineArrowTopRightOnSquare.vue'
    )
);

export const OutlineBell = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineBell.vue')
);

export const OutlineBookOpen = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineBookOpen.vue')
);

export const OutlineCalendar = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineCalendar.vue')
);

export const OutlineCheck = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineCheck.vue')
);

export const OutlineCheckCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineCheckCircle.vue')
);

export const OutlineChevronDown = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineChevronDown.vue')
);

export const OutlineClipboard = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineClipboard.vue')
);

export const OutlineCog = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineCog.vue')
);

export const OutlineDotsVertical = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineDotsVertical.vue')
);

export const OutlineEnvelope = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineEnvelope.vue')
);

export const OutlineExclamation = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineExclamation.vue')
);

export const OutlineEye = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineEye.vue')
);

export const OutlineFilter = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineFilter.vue')
);

export const OutlineFolderAdd = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineFolderAdd.vue')
);

export const OutlineHomeIcon = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineHomeIcon.vue')
);

export const OutlineInformationCircle = defineAsyncComponent(
  () =>
    import('@/components/icons/heroicons/outline/OutlineInformationCircle.vue')
);

export const OutlineKey = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineKey.vue')
);

export const OutlineLifeBouy = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineLifeBouy.vue')
);

export const OutlineMenu = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineMenu.vue')
);

export const OutlineMinusSm = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineMinusSm.vue')
);

export const OutlineMoon = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineMoon.vue')
);

export const OutlineNoSymbol = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineNoSymbol.vue')
);

export const OutlinePencil = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlinePencil.vue')
);

export const OutlinePlus = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlinePlus.vue')
);

export const OutlineQuestionMarkCircle = defineAsyncComponent(
  () =>
    import('@/components/icons/heroicons/outline/OutlineQuestionMarkCircle.vue')
);

export const OutlineScissors = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineScissors.vue')
);

export const OutlineSearch = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineSearch.vue')
);

export const OutlineShieldExclamation = defineAsyncComponent(
  () =>
    import('@/components/icons/heroicons/outline/OutlineShieldExclamation.vue')
);

export const OutlineStatistics = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineStatistics.vue')
);

export const OutlineSun = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineSun.vue')
);

export const OutlineSwitchVertical = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineSwitchVertical.vue')
);

export const OutlineTranslate = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineTranslate.vue')
);

export const OutlineUser = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineUser.vue')
);

export const OutlineUserCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineUserCircle.vue')
);

export const OutlineUsersIcon = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineUsersIcon.vue')
);

export const OutlineX = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineX.vue')
);

export const OutlineXCircle = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineXCircle.vue')
);

export const OutlineLink = defineAsyncComponent(
  () => import('@/components/icons/heroicons/outline/OutlineLink.vue')
);
